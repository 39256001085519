$color--bottle-green: #002726;
$color--cascade: #97a7a6;
$color--mint: #33c375;
$color--white: #ffffff;
$color--cream: #f0e8d6;
$color--grey-6: rgb(3, 10, 33, 3%);
$color--grey-5: rgba(3, 10, 33, 0.5);
$color--grey-4: rgba(3, 10, 33, 0.102);
$color--grey-3: rgb(3, 10, 33, 20%);
$color--grey-2: rgb(3, 10, 33, 30%);
$color--grey-1: rgb(3, 10, 33, 50%);

.section__selfpaced {
  padding: 120px 0 0 0;

  @media (max-width: 992px) {
    padding: 40px 0 0 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 46.8px */
      letter-spacing: -0.558px;
      color: $color--bottle-green;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      color: $color--bottle-green;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 20px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 992px) {
      gap: 16px;
    }
  }

  &-tab {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
    color: $color--bottle-green;
    white-space: nowrap;
    height: 52px;
    transition: all 0.4s;

    &:hover {
      background-color: #fffaf1;
    }

    &.active {
      background-color: $color--bottle-green;
      color: $color--white;
    }

    @media (max-width: 992px) {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      padding: 12px 10px;
      height: 41px;
    }
  }

  &-slider {
    margin-top: 32px;

    @media (max-width: 767px) {
      padding: 0 24px;
      margin-top: 24px;
    }

    .swiper-slide {
      width: fit-content !important;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */

        @media (max-width: 992px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
        }
      }
    }

    &-swiper {
      overflow-x: hidden;
      
      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }

      @media (max-width: 767px) {
        display: none !important;
      }

      &-mobile {
        display: none;

        @media (max-width: 767px) {
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;
        }
      }

      &-button {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 20px;
        border-radius: 12px;
        border: 1px solid $color--bottle-green;
        background-color: transparent;
        color: $color--bottle-green;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        cursor: pointer;
        font-family: inherit;
        text-decoration: none;
        margin-top: 24px;
        width: 100%;

        @media (max-width: 767px) {
          display: flex;
          height: 59px;
        }
      }
    }

    &-container {
      margin-top: 60px;

      @media (max-width: 992px) {
        margin-top: 72px;
      }
    }

    &-slide {
      height: 316px;
      width: 800px;
      border-radius: 20px;
      border: 1px solid $color--bottle-green;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      padding: 20px;
      gap: 24px;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background-color: #fffaf1;

        .section__selfpaced-slider-slide-header-stats--hover {
          opacity: 1;
        }
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 327px;
        height: 636px;
        padding: 16px;
        border-radius: 16px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }

      &-img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        // background: linear-gradient(126deg, #000000, #002726);
        position: relative;
        &-texture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.2;
          object-fit: cover;
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
          z-index: 999;
          position: absolute;
          bottom: -16%;
          filter: grayscale(100%);
          scale: 1.2;

          &.card-text-bg {
            z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        @media (max-width: 992px) {
          height: 295px;
          min-height: 295px;
          border-radius: 12px;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 20px;

        &-badge {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 16.8px */
          padding: 5px 10px;
          border: 1px solid $color--bottle-green;
          border-radius: 7.5px;
          align-self: flex-start;
          display: none;

          @media (max-width: 992px) {
            display: flex;
          }
        }
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }

        &-stats {
          display: flex;
          align-items: center;
          gap: 20px;
          transition: all 0.4s;
          margin-top: 8px;

          &--hover {
            margin: 0;

            @media (max-width: 992px) {
              opacity: 1;
            }
          }

          &-item {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 8px;

            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              text-transform: capitalize;
            }

            &-icon {
              width: 17px;
              height: 17px;
              display: grid;
              place-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        gap: 8px;

        &-images {
          display: flex;
          align-items: center;

          &-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid $color--grey-1;
            display: grid;
            place-items: center;
            background-color: #fffaf1;
            position: relative;

            @media (max-width: 992px) {
              width: 32px;
              height: 32px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:nth-child(2) {
              margin-left: -16px;
            }

            &:nth-child(3) {
              margin-left: -16px;
            }

            &:nth-child(4) {
              margin-left: -16px;
              background-color: $color--bottle-green;
              border-color: $color--white;
              color: $color--white;
            }
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */

          span {
            font-weight: 500;
            display: block;
            margin-top: 4px;
          }
        }
      }
    }

    &-card {
      border: 1px solid $color--bottle-green;
      border-radius: 20px;
      height: 426px;
      width: 315px;
      overflow: hidden;
      transition: all 0.4s;
      background-color: #fff;
      cursor: pointer;
      aspect-ratio: 1/1;
      &:hover {
        background-color: #fffaf1;
      }

      @media (max-width: 767px) {
        width: 100%;
        border-radius: 16px;
      }
      @media (max-width: 560px) {
        width: 100%;
      }

      .wrapper-div {
        height: 100%;
        width: 100%;
      }

      &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 24px;
        height: calc((100% - 184px));
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }
      }

      &-stats {
        display: flex;
        align-items: center;
        gap: 20px;
        transition: all 0.4s;

        &-item {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
          }

          &-icon {
            width: 17px;
            height: 17px;
            display: grid;
            place-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      &-author {
        display: flex;
        align-items: center;
        gap: 8px;

        &-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid $color--grey-1;
          display: grid;
          place-items: center;
          background-color: $color--bottle-green;
          position: relative;

          @media (max-width: 992px) {
            width: 32px;
            height: 32px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-transform: capitalize;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    }
  }

  &-arrows {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &-arrow {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    cursor: pointer;
    stroke: $color--bottle-green;
    transition: all 0.4s;

    &:hover {
      background-color: $color--bottle-green;
      stroke: $color--white;
    }

    &-previous {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-card-cta {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 60px;
    padding: 0 24px;

    @media (max-width: 992px) {
      margin-top: 36px;
    }

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      opacity: 0.2;
    }

    &-grid {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr auto;
      position: relative;
      z-index: 3;
      align-items: center;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      padding: 16px 32px;
      background: linear-gradient(90deg, #030a21 0%, #1543db 100%);
      background-size: 120% 100%;
      transition: background-position 0.4s;

      &:hover {
        background-position: 100% 0;
      }

      @media (max-width: 1280px) {
        max-width: 1092px;
      }

      @media (max-width: 992px) {
        padding: 16px 24px;
        border-radius: 12px;
      }
    }

    &-img {
      width: 88px;
      height: 88px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $color--white;
      position: relative;
      z-index: 3;

      h3 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.496px;

        @media (max-width: 992px) {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          letter-spacing: -0.372px;
        }

        @media (max-width: 576px) {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
        color: currentColor;
        text-decoration: none;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }

        @media (max-width: 576px) {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 19.2px */
        }
      }
    }
  }
}
